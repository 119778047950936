/*
<template>
  <div class="backShadow">
    <div v-if="!creatingPassword && !faultOnLogin" class="modal-container"> 
      <label v-if="newUser==='true'" class="textAverage">Criar Senha</label>
      <label v-else class="textAverage">Criar nova Senha</label>
      <input type="password" placeholder="Senha" class="textAverage" v-model="password" v-on:keyup.enter="login"/>
      <input type="password" placeholder="Confirmar Senha" class="textAverage" v-model="confirmPassword" v-on:keyup.enter="login"/>
      <div class="btn btnAverageSize btnTypeSave btnLogin" v-on:click="login" >Salvar</div>
    </div> 
    <PopUp  msg="Criando Senha..." :show="creatingPassword" type="Loader"/> 
    <PopUp  v-bind:msg="faultMsg" :show="faultOnLogin" type="Fault" :onOk="popUpOk"/>              
  </div>
</template>
<script>
  import UserControl from '@/sys/Control/User/UserControl'
  import PopUp from '@/views/PopUp/PopUp.vue'
  import router from '@/router/index'
  export default {
    components:{PopUp},
    props:{ 
      token:{
        type: String
      },
      tokenId:{
        type: String
      },
      newUser:{
        type: String
      }
    },
    data() {
      return {
        creatingPassword:false,
        faultOnLogin:false,
        faultMsg:null,
        email:null,
        password:null,
        confirmPassword:null,
        typeCreatePassword:null
      }
    },
    methods:{
      login: function(){
        this.creatingPassword=true;
        UserControl.resetPassword(this.token,this.tokenId,this.password,this.confirmPassword).then(result=>{
          console.log(result);
          router.push({path:'/Login'});
        }).catch(e=>{
          this.faultMsg=e;
          this.faultOnLogin=true;
          console.log(e);
          this.creatingPassword=false;
        });           
      },
      popUpOk: function(){
        this.faultOnLogin=false;
      }
    },
    created(){
      console.log("Criando Password");
      console.log(this.token);
      console.log(this.tokenId);
    }
  }  
</script>

<style scoped>
  label{
    width: 300px;
    text-align: center;
    margin:0px 0px 0px 0px ; 
  }
  input{
    height: 50px;
    width: 300px;
    background-color: #1e2227;
    border: none;
    outline: none;
    border-radius: 4px;
    text-align: center;
    margin:20px 0px 0px 0px ;
  }
  .btnLogin{
    width: 300px;
    margin:20px 0px 0px 0px ;
  }
  .router-link{
    text-decoration: none;
    color: white;
  }
</style>

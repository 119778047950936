import BackEnd from '@/sys/BackEnd/BanckEnd'

export default class UserControl{

	static validPassword(password) {
        let regexLower = new RegExp('(?=.*[a-z])')
	    let regexUpper = new RegExp('(?=.*[A-Z])')
        let regexDigits = new RegExp('(?=.*[0-9])')
        let regexSpecial=new RegExp('(?=.*[^!,%,&,@,#,$,^,*,?,_,~])');
        if (!password.match(regexLower) || !password.match(regexUpper)
        || !password.match(regexDigits) || !password.match(regexSpecial)
        || password.length<8){
            throw "A senha deve conter no minimo 8 carcteres, letra maiuscula, letra minuscula, numero e caracter especial!!!";
        }
    }

    static validEmail(email){
        var re = /\S+@\S+\.\S+/;
        if(!re.test(email)){
            throw "E-mail invalido!!!"
        }
    }

    static validEmailLogin(email){
        var re = /\S+@\S+\.\S+/;
        return !re.test(email)
    }

    static login(email,password){
        return new Promise(async function(resolve,reject){
            try{
                if(email!=null && password!=null){
                    let emailAux = email
                    if(UserControl.validEmailLogin(email)){
                        emailAux = emailAux + "@firebee.com.br"
                    }

                    await BackEnd.login(emailAux,password);
                    resolve("Usuario logado com sucesso");
                }else{
                    reject("Favor preencher todos os campos!!!");
                }
            }catch(e){
                if(typeof e.error != 'undefined' && e.error!=null ){
                    if(e.error==='Invalid username/password'){
                        reject("E-mail ou senha inválido(s)!!!");
                    }else{
                        console.log(e);
                        reject(e.error);
                    }
                }else{
                    console.log(e);
                    if(typeof e === 'string'){
                        reject(e);
                    }else{
                        reject("Usuário sem permissões de acesso, favor entrar em contato com o suporte.");
                    }
                }
            }
        });
    }

    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static registerUser(email,name,phone,type,permissionList){
        return new Promise(async function(resolve,reject){
            try{
                if(email!=null){
                    UserControl.validEmail(email);
                    await BackEnd.function().createUser({
                        _id:null,
                        name:name,
                        email:email,
                        phone:phone,
                        type:type,
                        permissionList:permissionList,
                        createdAt:new Date(),
                        updatedAt:new Date(),
                        deletedAt:null
                    });//insere esse usuario na coleção de usuarios
                    let resp=await BackEnd.registerUser(email+"teste");//registra o usuario na APP

                    resolve("Usurio registrado com sucesso!!!");
                }else{
                    reject("Favor preencher todos os campos!!!");
                }
            }catch(e){
                console.log("fault enter @@@@@@@@@@@");
                if(typeof e.error != 'undefined' && e.error!=null ){
                    if(e.error==='name already in use'){
                        reject("Nome de usuario em uso!!!");
                    }else{
                        console.log(e);
                        reject(e.error);
                    }
                }else{
                    console.log(e);
                    if(typeof e === 'string'){
                        reject(e);
                    }else{
                        reject("Verifique sua conexão!!!");
                    }
                }
            }
        });
    }

    static sendResetPasswordEmail(email){
        return new Promise(async function(resolve,reject){
            try{
                if(email!=null){
                    UserControl.validEmail(email);
                    console.log("Entrando na rotina de envio de e-mail");
                    await BackEnd.sendResetPasswordEmail(email,false);//envia e-mail de criação de senha mas para usuario existente
                    resolve("E-mail enviado com sucesso!!!");
                }else{
                    reject("Favor preencher todos os campos!!!");
                }
            }catch(e){
                if(typeof e.error != 'undefined' && e.error!=null ){
                    if(e.error==='user not found'){
                        reject("Usuário inválido!!!");
                    }else{
                        console.log(e);
                        reject(e.error);
                    }
                }else{
                    console.log(e);
                    if(typeof e === 'string'){
                        reject(e);
                    }else{
                        reject("Verifique sua conexão!!!");
                    }
                }
            }
        });
    }

    static resetPassword(token,tokenId,newPassword,confirmPassword){
        return new Promise(async function(resolve,reject){
            try{
                if(newPassword!=null && confirmPassword!=null && newPassword.length>0 && confirmPassword.length>0){
                    if(newPassword === confirmPassword){
                        UserControl.validPassword(newPassword);
                        await BackEnd.resetPassword(token,tokenId,newPassword);
                        resolve("Senha criada com sucesso");
                    }else{
                        reject("As senhas estão diferentes");
                    }

                }else{
                    reject("Favor preencher todos os campos");
                }
            }catch(e){
                if(typeof e.error != 'undefined' && e.error!=null ){
                    if(e.error==='userpass token is expired or invalid'){
                        reject("Link de criação de senha invalido ou expirado!!!");
                    }else{
                        console.log(e);
                        reject(e.error);
                    }
                }else{
                    console.log(e);
                    if(typeof e === 'string'){
                        reject(e);
                    }else{
                        reject("Verifique sua conexão!!!");
                    }
                }
            }
        });
    }

    static isLogged(){
        return BackEnd.isLogged();
    }
}